.topbar {
  display: flex;
  width: 100%;
  box-shadow: 5px;
  color: white;
  flex-direction: column;
  position: sticky;
  top: 0px;
  z-index: 99999;

  .logo-user {
    width: 100%;
    display: flex;
    background-color: #000000;
    .user {
      flex: 1 1;
      font-size: 20px;
      align-items: center;
      display: flex;
      height: 45px;
      width: 100%;
      text-align: right;
      justify-content: flex-end;
      margin-right: 20px;
      .foto {
        height: 40px;
        border-radius: 100%;
        contain: content;
      }
    }

    .logo {
      flex: 1;
      background: url("../img/logo.jpg") no-repeat;
      background-size: contain;
      height: 45px;
      width: 20%;
      display: flex;
      margin-left: 3%;
    }
  }

  .menu {
    display: flex;
    flex: 6;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #858585;

    .allSeccion {
      width: 100%;

      .itemSeccion {
        padding-right: 10px;
        padding-left: 10px;
      }

      .itemSeccion:hover {
        background-color: #767676;
      }

      .menuSeccion {
        display: flex;
        align-self: flex-start;
        width: 100%;
        text-align: left;
        padding-left: 200px;
      }

      .active {
        background-color: #cd950f;
      }
    }
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 10;
    background-color: #ffffff;
    border: 1px solid lightgray;
    -webkit-box-shadow: inset -5px 0px 7px -3px rgb(0 0 0 / 75%);
    -moz-box-shadow: inset -5px 0px 7px -3px rgba(0, 0, 0, 0.75);
    box-shadow: inset -5px 0px 4px -3px rgb(0 0 0 / 15%);
    z-index: 999;
    min-width: 150px; /* Establece un ancho mínimo para el menú */
    max-width: 300px; /* Opcional: Establece un ancho máximo para el menú */
  }

  .dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .dropdown-menu li {
    padding: 10px;
    cursor: pointer;
    color: #000000;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
  }

  .dropdown-menu li:hover {
    background: #f5f5f5;
  }
  .item {
    display: flex;
    .itemoicon svg {
      width: 35px;
      height: 22px;
      color: #000000;
    }
  }
}
