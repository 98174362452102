.sidebar {
    flex: 1;
    background-color: #FFFFFF;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: inset -5px 0px 7px -3px rgb(0 0 0 / 75%);
    -moz-box-shadow: inset -5px 0px 7px -3px rgba(0,0,0,0.75);
    box-shadow: inset -5px 0px 4px -3px rgb(0 0 0 / 15%);
    border-right: 1px solid lightgray;
    position: sticky;
    top: 69px;
    height: calc(100vh - 69px);
    .menu {
        width: 100%;
        display: flex;
        flex-direction: column;

        
    }
    .item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid lightgray;
        height: 51px;
        cursor: pointer;
        
        .itemoicon {
            padding-left: 0.5em;
            padding-right: 0.5em;
            .icon-clientes{
                background-image: url('../img/icons/clientes.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-proveedores{
                background-image: url('../img/icons/proveedores.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-agentes{
                background-image: url('../img/icons/comerciales.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-articulos{
                background-image: url('../img/icons/articulo.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-articulosCompra{
                background-image: url('../img/icons/entrada almacen.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-combinados{
                background-image: url('../img/icons/combinados.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-presupuestos{
                background-image: url('../img/icons/presupuesto venta.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-facturas{
                background-image: url('../img/icons/factura venta.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-facturas-compras{
                background-image: url('../img/icons/facturas compra.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-espacios{
                background-image: url('../img/icons/espacios.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-trabajadores{
                background-image: url('../img/icons/trabajadores.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-documentos{
                background-image: url('../img/icons/documentos.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
             .icon-calendario{
                background-image: url('../img/icons/calendario.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-pedido{
                background-image: url('../img/icons/pedido.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
        }
        .itemoicon svg {
            width: 35px;
            height: 22px;
        }
        .itemtext {
            font-size: 14px;
            font-weight: 300;
        }
    }
    .item:hover{
        background: #f5f5f5;
        box-shadow: inset -5px 0px 4px -3px rgb(0 0 0 / 15%);
    }
    .menuActive,  .menuActive:hover{
        background-color: #e3e3e3;
        box-shadow: inset -4px 4px 4px -3px rgb(0 0 0 / 15%), inset 0px -4px 4px -3px rgb(0 0 0 / 15%);
    }
    .logout{

        .itemoicon svg{
            width: 35px;
            height: 22px;
            
        }
    }
}

.collapsed {
    width: 51px;
    background-color: #FFFFFF;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    border-right: 1px solid lightgray;
    box-shadow: inset -5px 0px 4px -3px rgb(0 0 0 / 15%);
    position: sticky;
    top: 69px;
    height: calc(100vh - 69px);
    .menu {
        width: 100%;
        display: flex;
        flex-direction: column;

        
    }
    .item {
        display: flex;
        align-items: center;
        width: 51px;
        height: 51px;
        border-bottom: 1px solid lightgray;
        cursor: pointer;

        .itemoicon {
            margin-left: auto;
            margin-right: auto;
            .icon-clientes{
                background-image: url('../img/icons/clientes.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-proveedores{
                background-image: url('../img/icons/proveedores.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-agentes{
                background-image: url('../img/icons/comerciales.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-articulos{
                background-image: url('../img/icons/articulo.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-combinados{
                background-image: url('../img/icons/combinados.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-presupuestos{
                background-image: url('../img/icons/presupuesto venta.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-facturas{
                background-image: url('../img/icons/factura venta.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-facturas-compras{
                background-image: url('../img/icons/facturas compra.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-articulosCompra{
                background-image: url('../img/icons/entrada almacen.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-espacios{
                background-image: url('../img/icons/espacios.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-trabajadores{
                background-image: url('../img/icons/trabajadores.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-documentos{
                background-image: url('../img/icons/documentos.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-calendario{
                background-image: url('../img/icons/calendario.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
            .icon-pedido{
                background-image: url('../img/icons/pedido.svg');
                background-size: 35px;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
            }
        }

        .itemtext {
            font-size: 25px;
            font-weight: bold;
            visibility: hidden;
            width: 0px;
        }
        .itemoicon svg{
            width: 35px;
            height: 22px;
            
        }
    }
    .item:hover{
        background: #f5f5f5;
        box-shadow: inset -5px 0px 4px -3px rgb(0 0 0 / 15%);
    }
    .menuActive, .menuActive:hover{
        background-color: #e3e3e3;
        box-shadow: inset -4px 4px 4px -3px rgb(0 0 0 / 15%), inset 0px -4px 4px -3px rgb(0 0 0 / 15%);
    }

    .logout{
        .itemoicon svg{
            width: 35px;
            height: 22px;
            
        }
    }
}