@tailwind base;
@tailwind components;
@tailwind utilities;

form.shadow-md{
    position: relative!important;
    width: 350px!important;
    height: 500px!important;
    border-radius: 20px!important;
    padding: 40px!important;
    box-sizing: border-box!important;
    background: #ecf0f3!important;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white!important;
    margin-top: 5.5rem!important;
    
}

img.pt-6.pb-6{
    width: 100%!important;
}

.mb-4 {
    text-align: left!important;
    margin-top: 20px!important;
}

.div-pass label {
    margin-top: 15px;
}

label.block{
    display: block!important;
    width: 100%!important;
    padding: 0!important;
    border: none!important;
    outline: none!important;
    box-sizing: border-box!important;
    margin-bottom: 4px!important;
}

input.shadow{
    display: block!important;
    width: 100%!important;
    border: none!important;
    outline: none!important;
    box-sizing: border-box!important;
    background: #ecf0f3!important;
    padding: 10px!important;
    padding-left: 20px!important;
    height: 50px!important;
    font-size: 14px!important;
    border-radius: 50px!important;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white!important;
}

button.bg-blue-500{
    color: white!important;
    margin-top: 20px!important;
    background: #d3971d!important;
    height: 40px!important;
    border-radius: 20px!important;
    cursor: pointer!important;
    font-weight: 900!important;
    box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white!important;
    transition: 0.5s!important;
    display: block!important;
    width: 100%!important;
    padding: 0!important;
    border: none!important;
    outline: none!important;
    box-sizing: border-box!important;
}

button.bg-blue-500:hover{
      box-shadow: none!important;
}