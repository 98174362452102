.home {
    display: flex;
    flex-direction: column;

    .principal {
        display: flex;
        flex-direction: row;
    }
}

html {
    height: 100vh;
    overflow-y:hidden;
}