body {
  background-color: #FFFFFF;
}
.disabled {
  pointer-events: none;
}

.tituloVentana {
  font-size: 40px;
  font-weight: 300;
  color: darkgrey;
  text-align: center;
}
// .ReactModalPortal{
//   .button{
//     margin: 25px;
//   }
// }
.form {
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-size: 14px;
  background-color: #ffffff;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  margin-bottom: 3%;
  border: 2px solid lightgray;
  border-radius: 20px;
  .datos {
    display: flex;
    flex-direction: row;
    border-top: 2px solid lightgrey;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 1%;
    padding-right: 1%;
    .columna {
      display: flex;
      flex-direction: column;
      flex: 1;
      .grupo {
        margin: 10px 10px;
        display: inline-flex;
        .react-datepicker-wrapper{
          .react-datepicker__input-container{
            input{
              width: 100%;
              display: flex;
              padding-left: 10px;
              height: 40px;
              border: 1px solid lightgray;
            }
          }
        }
        .list {
          content: "";
          clear: both;
          display: table;
          width: 100%;
          .itemList {
            float: left;
            display: flex;
            flex-direction: row;
            width: 50%;
            padding: 10px 20px;
          }
        }
        .label {
          display: flex;
          width: 25%;
        }
        .text {
          display: flex;
          width: 70%;
          padding-left: 10px;
          height: 40px;
          border: 1px solid lightgray;
        }
        #pax{
          width: 100%;
        }
        #labelpax{
          width: 35%;
        }
        .switchText {
          border: none !important;
        }
        .grupoSelect {
          display: flex;
          flex: 3;
          .button {
            flex: 1;
          }
          .select {
            flex: 5;
          }
        }
      }
    }
    .segunda {
      flex: 3;
    }
  }
}
.margen {
  margin: 10px;
}
.table {
  display: flex;
  flex-direction: column;
}
.thead {
  display: flex;
  width: 100%;
}
.tbody {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.fila {
  width: 100%;
  margin-top: 10px;
  display: flex;
  .columna1 {
    width: 60%;
    padding-left: 20px;
    padding-right: 20px;
    .label{
      text-align: center;
    }
    .select{
      padding-left: 15px;
    }
  }
  .columna2 {
    width: 10%;
    margin-left: 10px;
    .text {
      width: 100%;
      border: 1px solid lightgray;
      text-align: center;
      padding-right: 5px;
    }
  }
  td.columna2 {
    width: 10%;
    margin-left: 10px;
    text-align: end;
  }
}
.fila2{
  width: 100%;
  margin-left: 25px;
  .columna1{
    width: 60%;
  }
  .columna2{
    width: 10%;
  }
  .iva{
    width: 100%;
  }
}
.text2 {
  width: 100%;
  text-align: center;
}
.gImagen {
  flex-direction: column;
  align-content: center;
  .image {
    object-fit: contain;
    object-position: center;
    max-width: 600px;
    width: 300px;
    height: 300px;
    display: block;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
  }
  .footerImage {
    margin-top: 10px;
    justify-content: center;
    display: flex;
    #imagen::-webkit-file-upload-button, #imagen {
      cursor: pointer;
    }
  }
}
.button {
  text-decoration: none;
  color: white;
  background-color: #CE9216;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 10px;
  min-width: 60px;
  height: 30px;
  margin-top: 4px;
}
.main {
  width: 120px;
  height: 40px;
  align-items: center;
  margin-top: 0;
  font-size: 25px;
  font-weight: 600;
}
.buttonMain {
  display: flex;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 34px;
  margin-top: 7px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 28px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ce9216
}

input:focus + .slider {
  box-shadow: 0 0 1px #ce9216;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.hidden {
  visibility: hidden;
}
.tarjetas {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px;
}
.card {
  border-radius: 15px;
  background-color: #d19f26;
  margin: 15px;
  /*box-shadow: rgba(0, 0, 0, 295) 0px 0px 10px;*/
  color: white;
  transform: translateY(0px);
  transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -ms-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;

  .contactName-Edit {
    padding-left: 10px;
    width: 95%;
    margin-left: -5px;
    margin-top: -5px;
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex;
    background-color: #333333;
    box-shadow: 4px 4px 5px -4px black;

    .label {
      flex: 1 1;
      padding-top: 5px!important;
      margin:0;
    }

    .iconosDiv {
      display: flex;

      .icono {
        margin: 10px;
      }
    }
  }
}

.card:hover{
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 65%);
  cursor: pointer;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.superior {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1%;
  padding-left: 1%;
}
.cardContent {
  margin: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  .iconosDiv {
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: row;
    .icono {
      margin: 10px;
    }
  }
}

.label {
  margin: 10px;
}
.signo {
  font-size: 80px;
  align-self: center;
  padding: 20px 0px 20px 0px;
  text-align: center;
}

h1 {
  font-size: 40px;
}
.tabs__list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ccc;
}

.react-tabs {
  width: 95%;
  -webkit-tap-highlight-color: transparent;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  margin-bottom: 3%;
  
}

.react-tabs__tab {
  border: 2px solid;
  border-bottom: 0;
  border-color: lightgray;
  border-radius: 15px 15px 0 0;
  cursor: pointer;
  display: inline-block;
  list-style: none;
  padding: 0px 12px;
  position: relative;
  background-color: white;
  margin-right: 8px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
  font-weight: 400;
  color: gray;
}
.react-tabs__tab--selected {
  background: #CB950E;
  border-color: lightgray;
  border-radius: 15px 15px 0 0;
  color: #fff;
}
.react-tabs__tab--disabled {
  color:GrayText;
  cursor:default
}
.react-tabs__tab:focus {
  outline:none
}
.react-tabs__tab-panel {
  display:none;
  background-color: white;
  
  h1{
    font-size: 40px;
    font-weight: 300;
    color: darkgrey;
    padding-left: 1%;
    border-bottom: 2px solid lightgrey;
  }
}
.react-tabs__tab-panel--selected {
  display:block;
  border: 2px solid lightgrey;
  border-top: 2px solid lightgray;
  border-radius: 15px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#columna2-prod-opcionales{
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: 20px;
}

.cardPresupuestos{
  width: 100%;
  height: 100%;
  margin: 5px;
}

.divCardPresupuestos{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 10px;
}

@keyframes rotate {from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}}
@-webkit-keyframes rotate {from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(360deg);}}
.circle{
    -webkit-animation: 3s rotate linear infinite;
    animation: 3s rotate linear infinite;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}