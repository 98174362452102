#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  background: white;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #fff4cf;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #d19f26;
  color: white;
}
.search {
  display: inline-flex;
  flex-direction: row;
  margin: 20px;
  align-content: flex-end;
  float:right;
  .text {
    background-image: url(https://www.w3schools.com/css/searchicon.png);
    background-position: 10px 10px;
    background-repeat: no-repeat;
    font-size: 16px;
    padding: 5px 20px 5px 40px;
    border: 1px solid #ddd;
    border-radius: 50px;
  }
}

#customers td{
  cursor: pointer;
}
